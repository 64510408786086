<template>
  <div>
    <el-button type="primary" @click="openWage()">打开工资拆分</el-button>
    <wageSplit :wageSplitVisible.sync="wageSplitVisible"></wageSplit>
  </div>
</template>

<script>
  import wageSplit from "./wageSplit"
  export default {
    name: "wageSplitDemo",
    components:{
      wageSplit
    },
    data(){
      return{
        wageSplitVisible:true,

      }
    },
    methods:{
      openWage(){
        this.wageSplitVisible = true;
      }
    }
  };
</script>

<style scoped>

</style>
