<template>
  <!--:normalizer="normalizer"-->
  <treeselect v-model="value" :multiple="true" :options="options"  />
</template>

<script>
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: "treeSelect",
    components: { Treeselect },
    data() {
      return {
        // define the default value
        value: null,
        // define options
        options: [{
          id: 'a',
          label: 'a',
          children: [{
            id: 'aa',
            label: 'aa',
          }, {
            id: 'ab',
            label: 'ab',
          }],
        }, {
          id: 'b',
          label: 'b',
        }, {
          id: 'c',
          label: 'c',
        }],
      }
    },
    methods:{
      // normalizer(node) {
      //   return {
      //     id: node.id,
      //     label: node.name,
      //     children: node.children,
      //   }
      // },
    }
  };
</script>

<style scoped>

</style>
