<template>
  <div class="defaultInvoice">
    <vxe-grid
      class="invoiceTable"
      :ref="refname"
      resizable
      show-footer
      show-footer-overflow
      height="300"
      :footer-method="footerMethod"
      :columns="names"
      :data="datas"
      :edit-config="{trigger: 'click', mode: 'row'}"
      :edit-rules="validRules"
      :loading="loading"
      keep-source
    >
      <template v-slot:operation="{ row }">
        <el-button type="text"   @click="deleteData(row)">删除</el-button>
      </template>
    </vxe-grid>
    <div class="tancengBtn">
      <el-button type="primary" size="small" @click="validEvent">确定</el-button>
      <el-button type="" size="small" @click="closeDialog()">取消</el-button>
    </div>
  </div>
</template>

<script>
  import { floatSum } from '@/util/util';
  export default {
    name: "defaultInvoice",
    props:{
      wage:{
        type:String,
        default:""
      },
      sumItem:{
        type: Array,
        default () {
          return []
        }
      },
      names:{
        type: Array,
        default () {
          return []
        }
      },
      datas: {
        type: Array,
        default () {
          return []
        }
      },
      refname: {
        type: String,
        default: ''
      }
    },
    data(){
      return{
        options:[
          {value: 'value1', label: 'label1'},
          {value: 'value2', label: 'label2'},
          {value: 'value3', label: 'label3'},
          {value: 'value4', label: 'label4'},
          {value: 'value5', label: 'label5'},
        ],
        validRules:{
          total:[
            { required: true, message: '请填写' },
            { pattern: /^(-)?(0|[1-9]\d*)(\s|$|\.\d{0,2})/ , message: '格式不正确' },
          ]
        }
      }
    },
    created(){

    },
    mounted(){
      this.$nextTick(() => {
        // select列
        let selects = ['bankType','bankAccount','bankAll'];
        for(var i = 0;i < selects.length;i++){
          let column = this.$refs[this.refname].getColumnByField(selects[i])
          column.editRender.options = this.options
          console.log(column.editRender)
        }
      })
    },
    methods: {
      // 校验
      async validEvent () {
        const errMap = await this.$refs[this.refname].validate().catch(errMap => errMap)
        if (errMap) {
          this.$message.error("请正确填写数据");
        } else {
          // 取出所有的金额，然后相加
          var money = [];
          // 获取临时数据
          this.$refs[this.refname].getInsertRecords().forEach((row) => {
            money.push(row.total)
          })
          this.datas.forEach((row) => {
            money.push(row.total)
          })
          debugger
          if(floatSum(money) > this.wage){
            this.$message.error("拆分金额合计大于工资总额");
          }else if(floatSum(money) < this.wage){
            this.$message.error("拆分金额合计小于工资总额");
          }
        }
      },
      // 插入数据
      insertEvent(){
        this.$refs[this.refname].insertAt( {
          type:"对私",
          name:"张三三",
          bankType:"--",
          bankName:"--",
          bankAccount:"--",
          bankAll:"--",
          area:"--",
          code:"--",
          total:"0"
        })
      },
      submitData(){
        this.datas = this.$refs[this.refname].getInsertRecords().concat(this.datas)
        console.log("数据",this.datas)
      },
      footerMethod ({ columns, data }) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计'
            }
            if (this.sumItem.includes(column.property)) {
              return this.$utils.sum(data, column.property)
            }
            return null
          })
        ]
      },
      deleteData(row){
        var that = this;
        this.$confirm('您确定要删除该数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.$refs[this.refname].remove(row)
          that.$message.success('删除成功!')

        }).catch(() => {
        });
      },
      closeDialog(){
        this.$emit('cancleSplit')
      },

    }
  };
</script>

<style scoped lang="scss">

  .invoiceTable{
    box-shadow:0px 1px 7px 0px rgba(0,0,0,0.1);
    border-radius: 5px;
    background: #fff;
    padding: 14px 20px;
    .vxe-table .vxe-body--column:not(.col--ellipsis),
    .vxe-table .vxe-footer--column:not(.col--ellipsis),
    .vxe-table .vxe-header--column:not(.col--ellipsis){
      padding: 0;
    }
    .vxe-table--filter-wrapper .vxe-default-input,
    .vxe-table--filter-wrapper .vxe-default-select,
    .vxe-table--filter-wrapper .vxe-default-textarea,
    .vxe-table .vxe-default-input,
    .vxe-table .vxe-default-select,
    .vxe-table .vxe-default-textarea{
      height: 26px;
      padding: 0 6px;
      color:#000;
      font-size: 12px;
    }
  }
</style>
