<template>
  <div>
    <el-button type="primary" @click="openBorrow()">打开借发票{{dialogBorrowVisible}}</el-button>
    <borrow :form="form" :rules="rules" :borrowLists="borrowLists" :dialogBorrowVisible.sync="dialogBorrowVisible"></borrow>
  </div>

</template>

<script>
  import borrow from "./borrowInvoice"
  export default {
    name: "borrowInvoiceDemo",
    components:{borrow},
    data(){
      return {
        dialogBorrowVisible:false,
        form: {
          date1: this.todayDate(),
          date2: this.todayDate(),
          total: 0,
          invoice: "是",
          remarks: ''
        },
        rules: {
          date1: [
            { type: 'string', required: true, message: '请选择申请时间', trigger: 'change' }
          ],
          date2: [
            { type: 'string', required: true, message: '请选择回款时间', trigger: 'change' }
          ],
          invoice: [
            { required: true, message: '请选择是否开电子票', trigger: 'change' }
          ],
        },
        borrowLists:[
          {
            borrowCompany:"瑞士安美资源有限公司",
            isSpecial:true,
            id:1,
            borrowData:{id:1234,item:"服务费",money:1234567.89,tax:1234.89}
          },
          {
            borrowCompany:"瑞士安美资源有限公司1",
            isSpecial:false,
            id:1,
            borrowData:{id:2234,item:"不知道什么费",money:1234567.89,tax:1234.89}
          },
          {
            borrowCompany:"瑞士安美资源有限公司222222222233333345678989",
            isSpecial:true,
            id:1,
            borrowData:{id:3234,item:"还有什么费",money:1234567.89,tax:1234.89}
          }
        ]
      }
    },
    methods:{
      openBorrow(){
        this.dialogBorrowVisible = true;
      },
      todayDate(){
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        console.log(currentdate)
        return currentdate;
      }
    }
  };
</script>

<style scoped>
</style>
