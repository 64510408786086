import { render, staticRenderFns } from "./wageSplitDemo.vue?vue&type=template&id=3b851f7a&scoped=true&"
import script from "./wageSplitDemo.vue?vue&type=script&lang=js&"
export * from "./wageSplitDemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b851f7a",
  null
  
)

export default component.exports