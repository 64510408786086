<template>
  <el-dialog
    ref=""
    width="1000px"
    id="wage"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="工资拆分"
    :visible.sync="wageSplitVisible"
  >
    <div class="invoiceDetail">
      <wageTop
        :invoice-company-data="invoiceData.employeeInfo"
        @insertEvent = "insertEvent"
      ></wageTop>
      <wageTable
        ref="invoiceTable"
        :names="invoiceData.splitLists.splitName"
        :datas="invoiceData.splitLists.splitList"
        :refname="invoiceData.invoiceRef"
        :wage="invoiceData.employeeInfo.employeeWage"
        :sumItem="['total']"
      ></wageTable>
    </div>
  </el-dialog>
</template>

<script>
  import wageTop from "./wageTop"
  import wageTable from "./wageTable"
  export default {
    name: "wageSplit",
    components:{wageTop,wageTable},
    data(){
      return {
        invoiceData:{
          invoiceRef:"invoiceRef",
          employeeInfo:{
            employeeName:"张三三三",
            employeeWage:"12000.00",
          },
          splitLists:
            {
              splitName:[
                { type: 'index',title:"序号", width: 50 },
                { field: 'type',width:"100px", title: '支付' },
                { field: 'name',width:"150px", title: '雇员姓名' },
                { field: 'bankType',width:"150px", title: '银行类别',editRender: { name: 'select',options:[],immediate:true,events: {change: this.changeEvent}} },
                { field: 'bankName',width:"120px", title: '开户名' },
                { field: 'bankAccount',width:"200px", title: '开户账户' ,editRender: { name: 'select',options:[],immediate:true,events: {change: this.changeEvent}} },
                { field: 'bankAll',width:"100px", title: '银行全称',editRender: { name: 'select',options:[],immediate:true,events: {change: this.changeEvent}} },
                { field: 'area',width:"50px", title: '账户地区' ,},
                { field: 'code',width:"50px", title: '工行地区代码' , },
                { field: 'total',width:"100px", title: '发放金额',editRender: { name: 'input',immediate:true,events: {blur: this.inputEvent}} },
                { title: '操作', width: "90px", showOverflow: true, fixed:"right", slots: { default: 'operation' }}
              ],
              splitList:[
                {
                  type:"对私",
                  name:"张三三",
                  bankType:"value1",
                  bankName:"张三三",
                  bankAccount:"value1",
                  bankAll:"value1",
                  area:"value1",
                  code:"0200",
                  total:"12000.00"
                }
              ],
            },
        },
      }
    },
    props: {
      wageSplitVisible: {
        type: Boolean,
        default: false,
      },
    },
    watch:{
      wageSplitVisible: function (newVal, oldVal) {
        this.$emit("update:wageSplitVisible",newVal);
      },
    },
    methods:{
      inputEvent({ row, rowIndex, column, columnIndex }){
        var patt1=new RegExp(/^(-)?(0|[1-9]\d*)(\s|$|\.\d{0,2})/);

        // 如果存在非数字和小数点的字符不做更改
        if(row.total&&patt1.test(row.total)){
          row.total = parseFloat(row.total).toFixed(2)
        }
      },
      changeEvent ({ row, rowIndex, column, columnIndex }){
        console.log("change")
        if(row.bankType == "value5"){
          row.bankName = "王五五"
        }
      },
      // 增加
      insertEvent(){
        this.$refs.invoiceTable.insertEvent()
      },
      // 关闭弹框
      cancleSplit(){
        this.wageSplitVisible = false;
      },
    }
  };
</script>

<style scoped>

</style>
