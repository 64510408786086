<template>
  <el-dialog
    ref=""
    width="880px"
    id="choose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="借发票申请"
    :visible.sync="dialogBorrowVisible"
  >
    <div class="borrow">
      <div class="borrowLeft">
        <div class="borrowLeftHead">
          <div class="borrowLeftTitle">
            <p>发票列表 (共计<span>3</span>张)</p>
          </div>
          <div class="borrowLeftTypes">
            <p><i class="ordinary"></i>普通发票</p>
            <p><i class="special"></i>专项发票</p>
          </div>
        </div>
        <div class="borrowLists">
          <div class="borrowList" v-for="item in borrowLists" :key="item.id">
            <p class="borrowListName" :class="item.isSpecial?'special':'ordinary'">{{item.borrowCompany}}</p>
            <div class="borrowListData">
              <el-row>
                <el-col>
                  <p><span>发票项目：</span>{{item.borrowData.item}}</p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <p><span>发票金额：</span>{{item.borrowData.money | NumFormat}}</p>
                </el-col>
                <el-col :span="12">
                  <!--假设id:1234,为服务费。如果不是服务费，销项税为空-->
                  <p v-if="item.borrowData.id == 1234"><span>销项税额：</span>{{item.borrowData.tax}}</p>
                  <p v-else><span>销项税额：</span>--</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="borrowRight">
        <p>申请信息</p>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="申请时间：" prop="date1" >
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="回款时间："  prop="date2">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date2"  value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="发票合计金额：" prop="total">
            <el-col>
              <el-input v-model="form.total" :disabled="true">
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="是否开电子票：" prop="invoice">
            <el-radio-group v-model="form.invoice">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注："  prop="remarks">
            <el-input type="textarea" v-model="form.remarks"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div
      class="marginTop30"
      style="text-align: center"
    >
      <el-button
        type="primary"
        @click="saveBorrow()"
      >
        确认
      </el-button>
      <el-button @click="cancleBorrow()">
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { floatSum } from '@/util/util';
  export default {
    name: "borrowInvoice",
    props:{
      dialogBorrowVisible:{
        type: Boolean,
        default :false,
      },
      form:{
        type:Object,
        default:() => {
          return {
            date1: new Date(),
            date2: new Date(),
            total: 0,
            invoice: "是",
            remarks: ''
          }
        }
      },
      rules:{
        type:Object,
        default:() => {
          return {}
        }
      },
      borrowLists:{
        type:Array,
        default:() => {
          return []
        }
      }
    },
    watch:{
      dialogBorrowVisible: function (newVal, oldVal) {
        console.log(newVal)
        this.$emit("update:dialogBorrowVisible",newVal);
      },
    },
    filters:{
      NumFormat(value){
        var intPart = Number(value) - Number(value)%1; //获取整数部分（这里是windy93的方法）
        var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断

        var floatPart = ".00"; //预定义小数部分
        var value2Array = value.toString().split(".");

        //=2表示数据有小数位
        if(value2Array.length == 2) {
          floatPart = value2Array[1].toString(); //拿到小数部分

          if(floatPart.length == 1) { //补0,实际上用不着
            return intPartFormat + "." + floatPart + '0';
          } else {
            return intPartFormat + "." + floatPart;
          }

        } else {
          return intPartFormat + floatPart;
        }
      }
    },
    mounted(){
      this.totalMoney();
    },
    methods:{
      saveBorrow(){
        this.$refs.form.validate((valid) => {
          if (valid) {
            console.log(this.form)
            this.$refs.form.resetFields();
            this.dialogBorrowVisible = false;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      cancleBorrow(){
        this.$refs.form.resetFields();
        this.dialogBorrowVisible = false;
      },
      // 计算合计金额
      totalMoney(){
        let counts = [];
        this.borrowLists.forEach(function(key) {
          counts.push(key.borrowData.money)
        })

        this.$set(this.form, "total", this.$options.filters['NumFormat'](floatSum(counts)))

      },

    }
  };
</script>

<style scoped lang="scss">
  .borrow{
    display: flex;
  }
  .borrowLeftHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .borrowLeftTitle p{
    color:#666666;
    font-size: 14px;
    font-weight: 600;
  }
  .borrowLeftTypes{
    font-size: 12px;
    color:#999999;
    p{
      margin: 0;
    }
    i{
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius:2px;
    }
  }
  .borrowLists{
    padding:0 20px;
    height: 350px;
    overflow-y: auto;
    .borrowList{
      background: #ffffff;
      width: 300px;
      margin-bottom: 20px;
      .borrowListName{
        padding-left:10px;
        font-size: 12px;
        color:#ffffff;
        font-size: 12px;
        font-weight: 600;
        line-height: 32px;
        position: relative;
        border-radius: 4px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .borrowListData{
        padding: 10px;
        box-shadow:0px 1px 4px 0px rgba(32,64,86,0.17);
        border-radius: 4px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .borrowListData p{
        color:#999;
        font-size: 12px;
        line-height: 2;
        span{
          color:#333;
        }
      }
    }
  }
  .ordinary{
    background: #7EB9E3;
  }
  .special{
    background: #E181CB;
  }
  .borrowRight{
    width: 400px;
    margin-left: 20px;
    p{
      text-align: center;
      color:#666666;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }

</style>





















